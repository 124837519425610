import Tooltip from '../../../components/Tooltip/Tooltip';
import React from 'react';
import {ReactComponent as SyncIcon} from '../../../assets/icons/sync-patient-icon.svg';
import Api from '../../../api/Api';
import {useOpenModal} from '../../../hooks/useOpenModal';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';
import {Button} from '../../../components/Button/Button';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

export function SyncButton({patientUUID}) {
    const {isOpen, handleOpen, handleClose} = useOpenModal();

    const handlePatientSync = async () => {
        handleOpen();

        try {
            await Api.post(`api/v1/patient/${patientUUID}/sync`, null,
                () => {
                    console.error('Post is succesfull');
                },
                (error) => {
                    console.error('Error:', error);
                }
            );
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Tooltip content={'Synchroniseer met Medicore'} placement={'bottom'}>
                <SyncIcon onClick={handlePatientSync} />
            </Tooltip>

            <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
                <ConfirmationModal.Header>Synchronisatie gestart</ConfirmationModal.Header>
                <Text $noMargin $align="center">Wacht enkele minuten en ververs uw browser.</Text>
                <StyledButton type="button" onClick={handleClose}>Begrepen</StyledButton>
            </ConfirmationModal>
        </>
    );
}