import styled from 'styled-components';
import {Checkbox, Input} from '../Input/Input';
import {Text} from '../Text/Text';
import {TextAreaField} from './TextAreaField';
import {useState} from 'react';
import {useController} from 'react-hook-form';

const StyledCheckboxesField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CheckboxWrapper = styled.label`
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    padding: 13px;
    display: flex;
    gap: 10px;
    // Should only wrap if option === other (so input field is on new line, but long texts in default options shouldn't wrap)
    ${({$shouldWrap}) => $shouldWrap && 'flex-wrap: wrap;'};
`;

const CheckboxLabel = styled(Text).attrs({
    as: "label"
})`
    display: inline-block;
    ${({$checked}) => $checked && 'font-weight: var(--fw-bold);'}
`;

const StyledInput = styled(Input)`
    border-radius: 8px;
    flex-basis: 100%;
    margin-top: 5px;
    border: 1px solid var(--color-input-background);
    box-shadow: unset;
`;

const StyledTextAreaField = styled(TextAreaField)`
    border-radius: 8px;
    flex-basis: 100%;
    margin-top: 5px;
    border: 1px solid var(--color-input-background);
    box-shadow: unset;
`;

// For this RHF controlled component, it is important that the defaultValues are only set via useForm (single source of truth) and that the value is on the correct index of the option
export const CheckboxesField = ({data, ...props}) => {
    if (props?.control) {
        return <ControlledCheckboxesField data={data} {...props} />
    }

    return <UncontrolledCheckboxesField data={data} {...props} />
}

const ControlledCheckboxesField = ({data, ...props}) => {
    const { field } = useController({
        control: props?.control,
        name: props?.name
    })

    const [value, setValue] = useState(field.value || []);

    return (
        <StyledCheckboxesField>
            {data.options.map((option, index) => {
                const checked = value.includes(option.id);

                return (
                    <CheckboxWrapper key={option.id} htmlFor={option.id} $shouldWrap={option.type === "Other"}>
                        <Checkbox
                            onChange={(e) => {
                                const valueCopy = [...value];
                                valueCopy[index] = e.target.checked ? e.target.value : null;
                                field.onChange(valueCopy);
                                setValue(valueCopy);
                            }}
                            checked={checked}
                            type="checkbox"
                            value={option.id}
                            id={option.id}
                            onBlur={() => { if (props.onFormFieldBlur) props.onFormFieldBlur() }}
                            rules={{ required: props.required }}
                            disabled={props.disabled}
                        />
                        <CheckboxLabel htmlFor={option.id} $checked={checked}>{option.title}</CheckboxLabel>

                        <Other
                            option={option}
                            checked={checked}
                            otherValue={data?.answer?.find(a => a.id === option.id)?.value}
                            {...props}
                        />
                    </CheckboxWrapper>
                );
            })}
        </StyledCheckboxesField>
    );
}

// DEPRECATED: UncontrolledCheckboxesField is still used in ViewAnswersModal (read only) but for new usage deprecated, use ControlledCheckboxesField instead
const UncontrolledCheckboxesField = ({data, ...props}) => {
    const watcher = props.watch ? props.watch(props.name) : null;

    return (
        <StyledCheckboxesField>
            {data.options.map((option) => {
                const checked = watcher ? watcher.includes(option.id) : (data?.answer?.find(item => item.id === option.id));

                return (
                    <CheckboxWrapper key={option.id} htmlFor={option.id} $shouldWrap={option.type === "Other"}>
                        <Checkbox
                            id={option.id}
                            type="checkbox"
                            defaultChecked={checked}
                            value={option.id}
                            {...(props.register && props.register(props.name, {
                                required: props.required,
                                onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                            }))}
                            disabled={props.disabled}
                        />
                        <CheckboxLabel htmlFor={option.id} $checked={checked}>{option.title}</CheckboxLabel>

                        <Other
                            option={option}
                            checked={checked}
                            otherValue={checked?.value}
                            {...props}
                        />
                    </CheckboxWrapper>
                );
            })}
        </StyledCheckboxesField>
    );
}

const Other = ({option, checked, otherValue, ...props}) => {
    return (
        <>
            {props?.identifier === "Diva" || props?.identifier === "DivaEnglish" ? (
                (option.type === "Other") && (
                    <StyledTextAreaField
                        id={`other-${option.id}`}
                        data={{answer: otherValue}}
                        onFormFieldBlur={props?.onFormFieldBlur}
                        register={props.register}
                        name={`other-${option.id}`}
                        disabled={props.disabled}
                    />
                )
            ) : (
                (option.type === "Other" && checked) && (
                    <StyledInput
                        type="text"
                        defaultValue={otherValue}
                        {...(props.register && props.register(`other-${option.id}`, {
                            onBlur: () => { if (props.onFormFieldBlur) props.onFormFieldBlur() }
                        }))}
                        disabled={props.disabled}
                    />
                )
            )}
        </>
    );
}