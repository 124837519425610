import { useRef, useState, useEffect } from "react";
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';

const StyledSegmentedControl = styled.div`
    --highlight-width: auto;
    --highlight-x-pos: 0;
`;

const Controls = styled.div`
    display: inline-flex;
    justify-content: space-between;
    background: var(--color-primary-transparent);
    border-radius: 999px;
    max-width: 500px;
    margin: auto;
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        background: var(--color-primary);
        border-radius: 999px;
        width: var(--highlight-width);
        transform: translateX(var(--highlight-x-pos));
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        ${({$ready}) => $ready && "transition: transform 0.3s ease, width 0.3s ease;"};
    }
`;

const Input = styled.input`
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
`;

const Segment = styled.div`
    min-width: 120px;
    position: relative;
    text-align: center;
    z-index: 1;
`;

const Label = styled.label`
    cursor: pointer;
    display: block;
    padding: 8px 42px;
    transition: color 0.5s ease;
    color: ${({$active}) => $active ? "var(--color-white)" : "var(--color-primary)"};
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    line-height: 29px;
`;

const SegmentedControl = ({className, name, segments, callback, defaultIndex = 0, controlRef}) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();
    const {pathname} = useLocation();

    // On page change, find and set the activeIndex (when using browser next/previous controls)
    useEffect(() => {
        const activeItem = segments?.find(item => item.value === pathname);
        const index = segments?.indexOf(activeItem);
        setActiveIndex(index)
        //eslint-disable-next-line
    }, [pathname]);

    // Prevent initial transition
    useEffect(() => {
        setTimeout(function () {
            componentReady.current = true;
        }, 100)
    }, []);

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current;
        const { style } = controlRef.current;

        style.setProperty("--highlight-width", `${offsetWidth}px`);
        style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);

    const onInputChange = (value, index) => {
        setActiveIndex(index);
        callback(value, index);
    };

    return (
        <StyledSegmentedControl ref={controlRef} className={className}>
            <Controls $ready={Boolean(componentReady.current)}>
                {segments?.map((item, i) => (
                    <Segment
                        key={item.value}
                        ref={item.ref}
                    >
                        <Input
                            id={item.label}
                            type="radio"
                            name={name}
                            value={item.value}
                            checked={i === activeIndex}
                            onChange={() => onInputChange(item.value, i)}
                        />
                        <Label htmlFor={item.label} $active={i === activeIndex}>{item.label}</Label>
                    </Segment>
                ))}
            </Controls>
        </StyledSegmentedControl>
    );
};

export default SegmentedControl;
