import React from 'react';
import styled, {css} from 'styled-components';
import {useTooltip} from '../../hooks/useTooltip';
import {Text} from "../Text/Text";
import {ReactComponent as RadioInactive} from "../../assets/icons/radio-inactive.svg";
import {ReactComponent as RadioBlueOutline} from "../../assets/icons/radio-blue-outline.svg";
import {NotificationStatuses} from "../../constants/enums";

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipContent = styled.div`
    pointer-events: none;
    visibility: ${({show}) => (show ? 'visible' : 'hidden')};
    background-color: var(--color-white);
    color: var(--color-grey-90);
    text-align: center;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    z-index: 99;
    white-space: nowrap;
    font-size: var(--fs-search-button);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .05);
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    ${({placement}) => (placement === "left" && css`
        right: -12px;
    `)};

    ${({placement}) => (placement === "right" && css`
        left: -12px;
    `)};
`;

const Triangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--color-white);
    top: 0;
    transform: translateY(-50%);

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    ${({placement}) => (placement === "left" && css`
        right: 20px;
    `)};

    ${({placement}) => (placement === "right" && css`
        left: 20px;
    `)};
`;

const TextWrapper = styled.div`
    margin-bottom: 20px;
`;

const ToolTipContentInner = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-end;
`;

const VisualStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
`;

const GreyLine = styled.div`
    width: 1px;
    height: 20px;
    background-color: var(--color-primary-light)
`;

export function getStatusTranslation(status) {
    if ([NotificationStatuses.Delivered, NotificationStatuses.Open, NotificationStatuses.Click].includes(status)) return "Aangekomen"
    if ([NotificationStatuses.Processed].includes(status)) return "Verwerkt"
    if ([NotificationStatuses.Dropped, NotificationStatuses.Bounce, NotificationStatuses.SpamReport].includes(status)) return "Mislukt"

    return "Onbekend"
}

const NotificationStatusTooltip = ({content, placement, children}) => {
    const {isOpen, handleMouseEnter, handleMouseLeave} = useTooltip();

    return (
        <TooltipWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <TooltipContent placement={placement} show={isOpen}>
                <TextWrapper>
                    <Text $noMargin $small><b>E-mail historie</b></Text>
                </TextWrapper>
                {content?.map((item, index) => (
                    <>
                        <ToolTipContentInner key={index + 99}>
                            {index === 0 ? (
                                <RadioBlueOutline/>
                            ) : (
                                <VisualStatusWrapper>
                                    <GreyLine/>
                                    <RadioInactive width={"12px"} height={"12px"}/>
                                </VisualStatusWrapper>
                            )
                            }
                            <Text key={index} $lineHeight={"0.75"} $small $noMargin><b>{getStatusTranslation(item.status)}</b> {item.eventTriggeredOn}</Text>
                        </ToolTipContentInner>
                        <Triangle placement={placement}/>
                    </>
                ))}
            </TooltipContent>
        </TooltipWrapper>
    );
};

export default NotificationStatusTooltip;