import {Button, buttonVariants} from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react';
import FormModal from '../../../components/Modal/FormModal';
import styled from 'styled-components';
import {useOpenModal} from '../../../hooks/useOpenModal';
import Api from '../../../api/Api';
import {Controller, useForm} from 'react-hook-form';
import {FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {Spinner} from '../../../components/Spinner/Spinner';
import {useFetcher, useParams} from 'react-router-dom';
import {Text} from '../../../components/Text/Text';
import {Statuses} from '../../../constants/enums';
import {PortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/PortalSelectField';

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 76px;
`;

export const AddQuestionnaire = () => {
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [status, setStatus] = useState(Statuses.LOADING);
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(isOpen) {
            if(Boolean(data)) setStatus(Statuses.REVALIDATING);

            const controller = new AbortController();

            Api.get("questionnaire", (res) => {
                setData(res?.map(item => ({value: item.id, label: item.title})));
            }, () => {
                setError(true);
            }, controller).then(() => setStatus(Statuses.IDLE));

            return () => {
                Api.abortRequest(controller);
            }
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const {control, handleSubmit, formState: { errors }} = useForm({
        defaultValues: {
            intent: "add-questionnaire",
        }
    });

    const fetcher = useFetcher();
    const {patientUUID} = useParams();

    const onSubmit = (values) => {
        const data = {
            intent: values?.intent,
            questionnaire: values?.questionnaire?.value,
        }
        setStatus(Statuses.SUBMITTING);
        setError(false);
        fetcher.submit(data, { method: "POST", action: `/patient/${patientUUID}/vragenlijsten`});
    }

    // Fetcher callback
    useEffect(() => {
        if (fetcher?.state === "idle") {
            if (fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if (fetcher?.data) handleClose();
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = status === Statuses.LOADING || status === Statuses.REVALIDATING || status === Statuses.SUBMITTING;

    return (
        <>
            <Button type="button" onClick={handleOpen}>Vragenlijst toevoegen</Button>

            <FormModal isOpen={isOpen} handleClose={handleClose}>
                <FormModal.Header handleClose={handleClose}>Vragenlijst toevoegen</FormModal.Header>

                {status === Statuses.LOADING &&
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                }

                {data &&
                    <form id="choose-questionnaire-form" onSubmit={handleSubmit(onSubmit)}>
                        <FormFieldWrapper
                            id="questionnaire"
                            label="Kies een vragenlijst"
                            required={true}
                            error={errors.questionnaire}
                        >
                            <Controller
                                name="questionnaire"
                                control={control}
                                rules={{ required: true }}
                                disabled={disabled}
                                defaultValue={data[0]}
                                render={({field}) => {
                                    return (
                                        <PortalSelectField
                                            {...field}
                                            options={data}
                                        />
                                    )
                                }}
                            />
                        </FormFieldWrapper>
                    </form>
                }

                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

                <FormModal.Footer>
                    <Box><StyledButton type="button" onClick={handleClose} $variant="secondary">Annuleren</StyledButton></Box>
                    <Box><StyledButton type="submit" form="choose-questionnaire-form" disabled={disabled} loading={disabled}>Toevoegen</StyledButton></Box>
                </FormModal.Footer>
            </FormModal>
        </>
    );
}