import styled, {css} from 'styled-components';
import {Text} from '../Text/Text';
import React from 'react';
import {ConditionalWrap} from '../Utils/Utils';

const wrapperVariants = {
    withTimeline: css`
        background-color: var(--color-white);
        border-radius: 16px;
        box-shadow: var(--box-shadow);
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    `,
    withoutTimeline: css`
        margin: 20px 0 0;
    `,
};

const listVariants = {
    withTimeline: css`
        display: flex;
        flex-direction: column;
        gap: 30px;
    `,
    withoutTimeline: css`
        width: 100%;
        max-width: 1000px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 60px;
        row-gap: 8px;

        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    `,
};

export const InfoListWrapper = styled.div`
    ${({$hasTimelineFeature}) => $hasTimelineFeature ? wrapperVariants.withTimeline : wrapperVariants.withoutTimeline};
`;

export const InfoList = styled.div`
    ${({$hasTimelineFeature}) => $hasTimelineFeature ? listVariants.withTimeline : listVariants.withoutTimeline};
`;

const StyledInfoListItem = styled.div`
    opacity: ${({$hide}) => ($hide ? '0' : '1')};
`;

const InfoListItemLabel = styled(Text).attrs({
    $bold: true,
    $noMargin: true
})`
    margin-bottom: 6px;
`;

const InfoListItemText = styled(Text).attrs({
    $noMargin: true
})`
    min-height: 20px;
`;

export const InfoListItem = ({className, label, children, wrapInText = true, hide = false}) => {
    return (
        <StyledInfoListItem $hide={hide} className={className}>
            <InfoListItemLabel>{label}</InfoListItemLabel>
            <ConditionalWrap
                condition={wrapInText}
                wrap={children => <InfoListItemText>{children}</InfoListItemText>}
            >
                {children}
            </ConditionalWrap>
        </StyledInfoListItem>
    );
}