import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import qs from 'qs';
import {fetchProtectedData} from '../../api/fetch';
import {Await, defer, Link, useAsyncValue, useLoaderData, useMatch, useNavigation, useOutletContext, useRouteLoaderData} from 'react-router-dom';
import React from 'react';
import {Spinner, SpinnerOverlay, SpinnerWrapper} from '../../components/Spinner/Spinner';
import {Text} from '../../components/Text/Text';
import Pagination from '../../components/Pagination';
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../../components/Table/Table';
import {Checkbox} from '../../components/Input/Input';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Tooltip from '../../components/Tooltip/Tooltip';
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {checkAccountPermissions} from '../../utils/helpers';
import {NotificationStatus} from "../Notifications/Partials/NotificationStatus";
import NotificationStatusTooltip from "../../components/Tooltip/NotificationStatusTooltip";

const PatientLink = styled(Link)`
    color: var(--color-blue-50);
    font-weight: var(--fw-bold);
    text-decoration: underline;
    cursor: pointer;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

const StyledWarning = styled(Warning)`
    width: 24px;
    height: 24px;
    vertical-align: middle;
    flex-shrink: 0;
    
    path {
        fill: var(--color-warning-error);
    }
`;

const StyledTable = styled(Table)`
    // CSS hack to fix tooltip overflow
    padding-bottom: 200px;
    margin-bottom: -200px;
`;

const StyledTooltip = styled(Tooltip)`
    width: max-content;
    max-width: 240px;
    white-space: unset;
    text-align: left;
    padding: 10px 12px;
`;

const StyledCheckbox = styled(Checkbox)`
    &:not(:checked):disabled {
        opacity: .5;
    }
`;

export async function waitingListTableLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_RESERVATIONS, Permissions.PATIENT_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const registeredFrom = searchParams.get("registeredFrom") ?? null;
    const registeredUntil = searchParams.get("registeredUntil") ?? null;
    const invitedFrom = searchParams.get("invitedFrom") ?? null;
    const invitedUntil = searchParams.get("invitedUntil") ?? null;
    const healthInsurance = searchParams.getAll("healthInsurance") ?? null;
    const location = searchParams.getAll("location") ?? null;
    const hasEmail = searchParams.get("hasEmail") ?? null;
    const confirmationNotificationStatus = searchParams.getAll("confirmationNotificationStatus") ?? null;
    const reminderNotificationStatus = searchParams.getAll("reminderNotificationStatus") ?? null;

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(registeredFrom && {registeredFrom}),
        ...(registeredUntil && {registeredUntil}),
        ...(invitedFrom && {invitedFrom}),
        ...(invitedUntil && {invitedUntil}),
        ...(healthInsurance && {healthInsurance}),
        ...(location && {location}),
        ...(hasEmail && {hasEmail}),
        ...(confirmationNotificationStatus && {confirmationNotificationStatus}),
        ...(reminderNotificationStatus && {reminderNotificationStatus}),
    }, { arrayFormat: 'comma' });

    const endpoint = url?.pathname?.includes("invited") ? "reservation/patients/invited" : "reservation/patients";
    const waitingListPromise = fetchProtectedData(request, `${endpoint}${queryString ? `?${queryString}` : ""}`)

    return defer({waitingListPromise})
}

const WaitingListTable = () => {
    const {selected, setSelected} = useOutletContext();
    const {waitingListPromise} = useLoaderData();

    return (
        <>
            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={waitingListPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    <DeferredWaitingList selected={selected} setSelected={setSelected} />
                </Await>
            </React.Suspense>
        </>
    );
}

function DeferredWaitingList({ selected, setSelected }) {
    const waitingListData = useAsyncValue()
    const {account} = useRouteLoaderData("root");
    const navigation = useNavigation()
    const isPending = navigation?.location?.pathname?.includes("/reservation/waiting-list");
    const matchWaitingList = useMatch("/reservation/waiting-list")
    const matchInviteList = useMatch("/reservation/waiting-list/invited")
    
    // Handle the checkboxes and select all (only if patient has an email)
    const handleSelectAllOnPage = ({ target: { checked }}) => {
        let _selected = Object.assign([], selected);

        if (checked) {
            waitingListData?.items?.forEach((item) => {
                if (item.hasEmail && !_selected.includes(item.id)) {
                    _selected.push(item.id)
                }
            })
        } else {
            const ids = waitingListData?.items?.filter(i => i.hasEmail)?.map(i => i.id)
            waitingListData?.items?.forEach((item) => {
                _selected = _selected.filter(s => !ids.includes(s))
            })
        }

        setSelected(_selected)
    }

    const selectedAll = () => {
        const ids = waitingListData?.items?.filter(i => i.hasEmail)?.map(i => i.id)
        return ids?.length > 0 ? ids.every(s => selected.includes(s)) : false;
    }

    const toggleSelected = (id) => {
        if (!selected.includes(id)) {
            setSelected([...selected, id])
        } else {
            setSelected(selected.filter(s => s !== id))
        }
    }

    const canUseCheckboxes = () => {
        return (checkAccountPermissions(account, Permissions.INVITE_CREATE) && Boolean(matchWaitingList));
    }

    return (
        <>
            <RelativeWrapper>
                {waitingListData?.items?.length > 0 ? (
                    <>
                        <Pagination searchResults={waitingListData} $position="top"/>
                        <StyledTable>
                            <TableHead>
                                <TableHeadRow>
                                    {canUseCheckboxes() && (
                                        <TableHeadCell $flex="0">
                                            <Checkbox type='checkbox' checked={selectedAll()} onChange={handleSelectAllOnPage} />
                                        </TableHeadCell>
                                    )}
                                    <TableHeadCell>Naam patient</TableHeadCell>
                                    <TableHeadCell>Locatie</TableHeadCell>
                                    <TableHeadCell>Zorgverzekeraar</TableHeadCell>
                                    <TableHeadCell>Aanmelding</TableHeadCell>
                                    { Boolean(matchInviteList) &&
                                        <>
                                            <TableHeadCell>Uitnodiging</TableHeadCell>
                                            <TableHeadCell>E-mail #1</TableHeadCell>
                                            <TableHeadCell>E-mail #2</TableHeadCell>
                                        </>

                                    }
                                </TableHeadRow>
                            </TableHead>
                            {waitingListData?.items?.map((item, rowIndex) => {
                                return (
                                    <TableRow key={`row-${rowIndex}`}>
                                        <TableRowInner $isOddRow={rowIndex % 2 === 0}>
                                            {canUseCheckboxes() && (
                                                <TableRowCell $flex="0">
                                                    <StyledCheckbox type='checkbox' checked={selected.includes(item.id)} disabled={!item.hasEmail} onChange={() => toggleSelected(item.id)} />
                                                </TableRowCell>
                                            )}
                                            <TableRowCell $alignItems="center" $gap="8px">
                                                {!item.hasEmail && (
                                                    <StyledTooltip content="De patiënt beschikt niet over een e-mailadres, waardoor een uitnodiging niet verzonden kan worden.">
                                                        <StyledWarning />
                                                    </StyledTooltip>
                                                )}
                                                <PatientLink to={{pathname: `/patient/${item.id}`}}>
                                                    { item.name }
                                                </PatientLink>
                                            </TableRowCell>
                                            <TableRowCell>
                                                { item.location?.[0] }
                                            </TableRowCell>
                                            <TableRowCell>
                                                { item.healthInsurance }
                                            </TableRowCell>
                                            <TableRowCell>
                                                { item.registerDate != null && dayjs(item.registerDate).format("DD-MM-YYYY") }
                                            </TableRowCell>
                                            { Boolean(matchInviteList) &&
                                                <>
                                                    <TableRowCell>{ item.inviteDate != null && dayjs(item.inviteDate).format("DD-MM-YYYY") }</TableRowCell>
                                                    <TableRowCell>
                                                        <NotificationStatusTooltip content={item.confirmationNotification?.statuses ?? null} placement={"right"}>
                                                            <NotificationStatus status={item.confirmationNotification?.statuses?.[0]?.status ?? null}/>
                                                        </NotificationStatusTooltip>
                                                    </TableRowCell>
                                                    <TableRowCell>
                                                        <NotificationStatusTooltip content={item.reminderNotification?.statuses ?? null} placement={"left"}>
                                                            <NotificationStatus status={item.reminderNotification?.statuses?.[0]?.status ?? null}/>
                                                        </NotificationStatusTooltip>
                                                    </TableRowCell>
                                                </>
                                            }
                                        </TableRowInner>
                                    </TableRow>
                                );
                            })}
                        </StyledTable>
                        <Pagination searchResults={waitingListData} />
                    </>
                ) : (
                    <Text>Geen resultaten gevonden</Text>
                )}

                {isPending &&
                    <SpinnerOverlay>
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    </SpinnerOverlay>
                }
            </RelativeWrapper>
        </>
    )
}

export default WaitingListTable;