import {PortalSelectField} from './PortalSelectField';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Text} from '../../Text/Text';
import {useSearchParams} from "react-router-dom";

const StyledControlledPortalSelectField = styled.div`
    position: relative;
`;

const SelectAll = styled(Text).attrs({
    $bold: true,
    $noMargin: true,
    as: "button"
})`
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - 10px));
    text-align: right;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: var(--color-primary);
    font-size: var(--fs-select-all);
    cursor: pointer;
`;

export const ControlledPortalSelectField = ({options, isMulti, defaultValue, onChange, ...props}) => {
    const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);
    const [searchParams] = useSearchParams();

    const handleSelectAll = () => {
        setSelectedOptions(options);
        if (Boolean(onChange)) {
            onChange(options);
        }
    }

    const handleChange = (selected) => {
        setSelectedOptions(selected || []);
        if (Boolean(onChange)) {
            onChange(selected || []);
        }
    }

    useEffect(() => {
        if (searchParams.size <= 0) {
            setSelectedOptions([])
        }
    }, [searchParams]);

    return (
        <StyledControlledPortalSelectField>
            <PortalSelectField
                isMulti={isMulti}
                options={options}
                onChange={handleChange}
                value={selectedOptions}
                {...props}
            />
            {isMulti && <SelectAll onClick={handleSelectAll}>Selecteer alle</SelectAll>}
        </StyledControlledPortalSelectField>
    );
}