// Get unique list of objects by key (source https://stackoverflow.com/a/56768137)
import {Environments} from "../constants/enums";

export function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

// Check if item in parameter is an object
export function isObject(item) {
    return typeof item === "object" && !Array.isArray(item) && item !== null;
}

// Check if the account has the sufficient permission
export function checkAccountPermissions(account, permissionToCheck) {
    if(Array.isArray(permissionToCheck) && permissionToCheck?.length > 0) {
        return permissionToCheck.every(permission => account?.permissions?.includes(permission));
    }

    return account?.permissions?.includes(permissionToCheck);
}

// Check if the account has the sufficient feature permissions
export function hasFeatureAccess(account, featureToCheck) {
    if (Array.isArray(featureToCheck) && featureToCheck?.length) {
        return featureToCheck.every(feature => account?.features?.includes(feature));
    }

    return account?.features?.includes(featureToCheck);
}

export function checkAnyAccountPermissions(account, permissionsToCheck) {
    return account?.permissions?.some(p => permissionsToCheck.includes(p))
}

// Remove empty values from object (source https://medium.com/@zaitsev1393/best-way-to-remove-empty-values-from-js-objects-ad691bd084b9)
export const removeEmptyValues = (object) => {
    return typeof (!!object && object === "object" && !Array.isArray(object))
        ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
        : object;
}

// Get current environment based on .env variable
export const getCurrentEnvironment = () => {
    const currentEnvironment = process.env.REACT_APP_ENVIRONMENT_TYPE;

    switch (currentEnvironment) {
        case Environments.DEV:
            return {type: Environments.DEV, showLabel: true, showBar: true, label: "DEV"};
        case Environments.TEST:
            return {type: Environments.TEST, showLabel: true, showBar: true, label: "TEST"};
        case Environments.ACCEPT:
            return {type: Environments.ACCEPT, showLabel: true, showBar: true, label: "ACCEPTANCE", shortLabel: "ACC"};
        case Environments.PRODUCTION:
            // Do not show the bar on production
            return {type: Environments.PRODUCTION, showLabel: false, showBar: false};
        default:
            return {type: Environments.DEFAULT, showLabel: false, showBar: true, label: "Let op: Er is geen REACT_APP_ENVIRONMENT_TYPE ingesteld."};
    }
}

// Hide dynamic pathnames with useMatches as input
function matchPathname(pathname, pattern) {
    const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '([^\\s/]+)')}$`);
    return regex.test(pathname);
}

export function doesMatchSome(matches, pathnamePatterns) {
    return matches.some(match => {
        return pathnamePatterns.some(pattern => matchPathname(match.pathname, pattern));
    });
}

// Calcualte age with date string in format "DD-MM-YYYY"
export const calculateAge = (birthDateString) => {
    const [day, month, year] = birthDateString.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const hasBirthdayPassed = (today.getMonth() > birthDate.getMonth()) || (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());
    if (!hasBirthdayPassed) {
        age--;
    }

    return age;
}

// Join an array with comma, and the last one with & sign
export const joinWithCommaAndAmpersand = (arr) => {
    if (!arr || arr.length === 0) return "";
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(" & ");
    return arr.slice(0, -1).join(', ') + ' & ' + arr.slice(-1);
}