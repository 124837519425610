export const Statuses = {
    LOADING: "loading",
    REVALIDATING: "revalidating",
    IDLE: "idle",
    SUBMITTING: "submitting",
    SUCCESS: "success"
};

// NOTE: The commented permissions are unused in the portal (yet)
export const Permissions = {
    PORTAL_ACCESS_SEARCH: "PORTAL_ACCESS_SEARCH",
    PORTAL_ACCESS_RESERVATIONS: "PORTAL_ACCESS_RESERVATIONS",
    PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW: "PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW",
    PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW: "PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW",
    PORTAL_ACCESS_PATIENT_SUMMARY: "PORTAL_ACCESS_PATIENT_SUMMARY",

    APPOINTMENT_VIEW: "APPOINTMENT_VIEW",
    // APPOINTMENT_READ: "APPOINTMENT_READ",
    // APPOINTMENT_EDIT: "APPOINTMENT_EDIT",
    APPOINTMENT_DELETE: "APPOINTMENT_DELETE",
    APPOINTMENT_CREATE: "APPOINTMENT_CREATE",

    DOCUMENT_VIEW: "DOCUMENT_VIEW",
    DOCUMENT_READ: "DOCUMENT_READ",
    // DOCUMENT_EDIT: "DOCUMENT_EDIT",
    DOCUMENT_DELETE: "DOCUMENT_DELETE",
    DOCUMENT_CREATE: "DOCUMENT_CREATE",

    NOTIFICATION_VIEW: "NOTIFICATION_VIEW",
    NOTIFICATION_READ: "NOTIFICATION_READ",
    NOTIFICATION_EDIT: "NOTIFICATION_EDIT",
    NOTIFICATION_DELETE: "NOTIFICATION_DELETE",
    NOTIFICATION_CREATE: "NOTIFICATION_CREATE",

    PATIENT_VIEW: "PATIENT_VIEW",
    PATIENT_READ: "PATIENT_READ",
    // PATIENT_EDIT: "PATIENT_EDIT",
    // PATIENT_DELETE: "PATIENT_DELETE",
    // PATIENT_CREATE: "PATIENT_CREATE",

    // General questionnaires
    PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_VIEW: "PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_VIEW",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_READ: "PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_READ",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_EDIT: "PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_EDIT",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_DELETE: "PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_DELETE",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_CREATE: "PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_CREATE",

    // Screening questionnaires
    PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_VIEW: "PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_VIEW",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_READ: "PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_READ",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_EDIT: "PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_EDIT",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_DELETE: "PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_DELETE",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_CREATE: "PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_CREATE",

    // Medical questionnaires
    PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_VIEW: "PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_VIEW",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_READ: "PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_READ",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_EDIT: "PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_EDIT",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_DELETE: "PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_DELETE",
    PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_CREATE: "PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_CREATE",

    // INVITE_VIEW: "INVITE_VIEW",
    // INVITE_READ: "INVITE_READ",
    // INVITE_EDIT: "INVITE_EDIT",
    // INVITE_DELETE: "INVITE_DELETE",
    INVITE_CREATE: "INVITE_CREATE",

    PARTICIPANT_VIEW: "PARTICIPANT_VIEW",
    // PARTICIPANT_READ: "PARTICIPANT_READ",
    // PARTICIPANT_EDIT: "PARTICIPANT_EDIT",
    PARTICIPANT_DELETE: "PARTICIPANT_DELETE",
    // PARTICIPANT_CREATE: "PARTICIPANT_CREATE"``
}

export const Features = {
    TIMELINE: "TIMELINE"
}

export const Environments = {
    DEV: "dev",
    TEST: "test",
    ACCEPT: "acceptance",
    PRODUCTION: "production",
    DEFAULT: "default",
}

export const LogoutReasons = {
    TOKEN_EXPIRED: "token_expired",
    MANUALLY: "manually",
}

export const NotificationStatuses = {
    Delivered: "Delivered",
    Click: "Click",
    Open: "Open",
    Processed: "Processed",
    Dropped: "Dropped",
    Bounce: "Bounce",
    SpamReport: "SpamReport"
}