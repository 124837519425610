import qs from 'qs';
import Cookies from 'js-cookie';
import {redirect} from 'react-router-dom';
import {Spinner} from '../../components/Spinner/Spinner';
import styled from 'styled-components';
import {getHomePage} from './Login';
import {resetRootLoaderPromise} from '../Root/Root';

const SpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export async function loginCallbackLoader({request}) {
    const url = new URL(request.url);
    const queryString = qs.stringify(Object.fromEntries(url.searchParams));
    const decodedState = JSON.parse(atob(url.searchParams.get("state")));
    let redirectUrl = decodedState?.params?.redirectUrl?.[0];

    try {
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/oauth/callback/?${queryString}`, {
            signal: request.signal
        });

        if (!res.ok) throw res

        const data = await res.json();
        const currentTime = new Date().getTime();
        const expirationDate = new Date(currentTime + data.expires_in * 1000);

        Cookies.set('adhdcentraal-portal_token', data.access_token, { expires: expirationDate, secure: true });
        Cookies.set('adhdcentraal-portal_expiration', expirationDate, { expires: expirationDate, secure: true });
        Cookies.set('adhdcentraal-refresh_token', data.refresh_token, { expires: expirationDate, secure: true });

        // Get account to redirect to correct url based on permissions
        const accountRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/account`, {
            signal: request.signal,
            headers: {
                Authorization: `Bearer ${data.access_token}`,
            },
        });

        if(accountRes.ok) {
            // Reset rootLoaderPromise to fetch new account
            resetRootLoaderPromise();

            const account = await accountRes.json();
            if (account && !redirectUrl) {
                redirectUrl = getHomePage(account);
            }
        }

        return redirect(redirectUrl ?? "/patient-niet-gevonden");
    } catch (error) {
        const errorData = await error.json()
        throw new Response(errorData.message, { status: error.status, statusText: errorData.code });
    }
}

const LoginCallback = () => {
    return (
        <SpinnerWrapper>
            <Spinner />
        </SpinnerWrapper>
    );
}

export default LoginCallback;