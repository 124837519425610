import styled, {css} from 'styled-components';

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

export const Text = styled.p`
    font-size: ${({$small}) => $small ? 'var(--fs-text-small)' : 'var(--fs-text)'};
    color: ${({$error, $white}) => $error ? 'var(--color-error)' : $white ? 'var(--color-white)' : 'var(--color-text)'};
    line-height: ${({$lineHeight}) => $lineHeight ?? 1.25};
    ${({$noMargin}) => $noMargin && 'margin: 0;'};
    overflow-wrap: anywhere;
    text-align: ${({$align}) => $align === "center" ? "center" : "left"};
    ${({$italic}) => $italic && 'font-style: italic;'};
    ${({$bold}) => $bold && 'font-weight: var(--fw-bold);'};
    ${({ $truncate }) => $truncate && truncateMixin};
    ${({$margin}) => $margin && `margin: ${$margin};`};
    
    a {
        color: var(--color-primary);
        
        &:hover {
            color: var(--color-primary-hover);
            text-decoration: none;
        }
    }
`;

export const HTMLText = styled(Text).attrs({
    as: "div",
})`
    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    ul { padding-left: 20px; }
    ol { padding-left: 20px; }
`;

export const MarkdownText = ({className, text, ...props}) => {
    if(!text) return null;

    const parsedText = text.replace(/\[([^[]+)](\(([^)]*))/gim, '<a href="$3" target="_blank" rel="noreferrer">$1</a>');

    return (
        <Text
            className={className}
            dangerouslySetInnerHTML={{__html: parsedText}}
            {...props}
        />
    );
}