export const updateDivaSummary = (summaryData, questionGroup, index, values) => {
    // Get the selected answer's UUID (Maturity symptom question is index 2 and childhood symptom question is index 4)
    // If there is no values param, usage is in viewing answers
    let maturitySymptomAnswerUuid = values ? values[questionGroup?.questions?.[2]?.id] : questionGroup?.questions?.[2]?.answer;
    let childhoodSymptomAnswerUuid = values ? values[questionGroup?.questions?.[4]?.id] : questionGroup?.questions?.[4]?.answer;

    // Find the title of the selected option and set it in the summary data
    const maturitySymptomAnswerValue = questionGroup?.questions?.[2]?.options?.find(option => option?.id === maturitySymptomAnswerUuid?.id)?.title;
    const childhoodSymptomAnswerValue = questionGroup?.questions?.[4]?.options?.find(option => option?.id === childhoodSymptomAnswerUuid?.id)?.title;

    if (maturitySymptomAnswerValue && summaryData[index]) {
        summaryData[index].presentMaturity = maturitySymptomAnswerValue;
    }

    if (childhoodSymptomAnswerValue && summaryData[index]) {
        summaryData[index].presentChildhood = childhoodSymptomAnswerValue;
    }
}

export const summaryPart1 = [
    {
        id: "A1",
        criterion: "A1a",
        symptom: "A1. Slaagt er vaak niet in voldoende aandacht te geven aan details of maakt achteloos fouten in schoolwerk, werk of bij andere activiteiten",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A2",
        criterion: "A1b",
        symptom: "A2. Heeft vaak moeite de aandacht bij taken of spel te houden",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A3",
        criterion: "A1c",
        symptom: "A3. Lijkt vaak niet te luisteren als hij/zij direct aangesproken wordt",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A4",
        criterion: "A1d",
        symptom: "A4. Volgt vaak aanwijzingen niet op en slaagt er niet in schoolwerk, karweitjes af te maken of verplichtingen op het werk na te komen",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A5",
        criterion: "A1e",
        symptom: "A5. Heeft vaak moeite met het organiseren van taken en activiteiten",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A6",
        criterion: "A1f",
        symptom: "A6. Vermijdt vaak, heeft een afkeer van of is onwillig zich bezig te houden met taken die een langdurige geestelijke inspanning vereisen",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A7",
        criterion: "A1g",
        symptom: "A7. Raakt vaak dingen kwijt die nodig zijn voor taken of bezigheden",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A8",
        criterion: "A1h",
        symptom: "A8. Wordt vaak gemakkelijk afgeleid door uitwendige prikkels",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A9",
        criterion: "A1i",
        symptom: "A9. Is vaak vergeetachtig bij dagelijkse bezigheden",
        presentMaturity: "",
        presentChildhood: ""
    },
];

export const summaryPart1English = [
    {
        id: "A1",
        criterion: "A1a",
        symptom: "A1. Often fails to give close attention to details, or makes careless mistakes in schoolwork, work or during other activities",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A2",
        criterion: "A1b",
        symptom: "A2. Often has difficultly sustaining attention in tasks or play activities",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A3",
        criterion: "A1c",
        symptom: "A3. Often does not seem to listen when spoken to directly",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A4",
        criterion: "A1d",
        symptom: "A4. Often does not follow through on instructions and fails to finish schoolwork, chores, or duties in the workplace",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A5",
        criterion: "A1e",
        symptom: "A5. Often has difficulty organizing tasks and activities",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A6",
        criterion: "A1f",
        symptom: "A6. Often avoids, dislikes, or is reluctant to engage in tasks that require sustained mental effort",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A7",
        criterion: "A1g",
        symptom: "A7. Often loses things necessary for tasks or activities",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A8",
        criterion: "A1h",
        symptom: "A8. Often easily distracted by extraneous stimuli",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "A9",
        criterion: "A1i",
        symptom: "A9. Often forgetful in daily activities",
        presentMaturity: "",
        presentChildhood: ""
    },
];

export const summaryPart2 = [
    {
        id: "H/I1",
        criterion: "A2a",
        symptom: "H/I 1. Beweegt vaak onrustig met handen of voeten, of draait in zijn/haar stoel",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I2",
        criterion: "A2b",
        symptom: "H/I 2. Staat vaak op in de klas of in andere situaties waar verwacht wordt dat men op zijn plaats blijft zitten",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I3",
        criterion: "A2c",
        symptom: "H/I 3. Rent vaak rond of klimt overal op in situaties waarin dit ongepast is (bij adolescenten of volwassenen kan dit beperkt zijn tot subjectieve gevoelens van rusteloosheid)",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I4",
        criterion: "A2d",
        symptom: "H/I 4. Kan moeilijk rustig spelen of zich bezighouden met ontspannende activiteiten",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I5",
        criterion: "A2e",
        symptom: "H/I 5. Is vaak 'in de weer' of 'draaft maar door'",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I6",
        criterion: "A2f",
        symptom: "H/I 6. Praat vaak aan een stuk door",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I7",
        criterion: "A2g",
        symptom: "H/I 7. Gooit het antwoord eruit voordat de vragen afgemaakt zijn",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I8",
        criterion: "A2h",
        symptom: "H/I 8. Heeft vaak moeite op zijn/haar beurt te wachten",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I9",
        criterion: "A2i",
        symptom: "H/I 9. Verstoort vaak bezigheden van anderen of dringt zich op",
        presentMaturity: "",
        presentChildhood: ""
    },
];

export const summaryPart2English = [
    {
        id: "H/I1",
        criterion: "A2a",
        symptom: "H/I 1. Often fidgets with or taps hands or feet or squirms in seat",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I2",
        criterion: "A2b",
        symptom: "H/I 2. Often leaves seat in situations when remaining seated is expected",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I3",
        criterion: "A2c",
        symptom: "H/I 3. Often runs about or climbs in situations where it is inappropriate",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I4",
        criterion: "A2d",
        symptom: "H/I 4. Often unable to play or take part in leisure activities quietly",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I5",
        criterion: "A2e",
        symptom: "H/I 5. Is often 'on the go' acting as if 'driven by a motor'",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I6",
        criterion: "A2f",
        symptom: "H/I 6. Often talks excessively",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I7",
        criterion: "A2g",
        symptom: "H/I 7. Often blurts out an answer before a question has been completed",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I8",
        criterion: "A2h",
        symptom: "H/I 8. Often has difficulty awaiting his or her turn",
        presentMaturity: "",
        presentChildhood: ""
    },
    {
        id: "H/I9",
        criterion: "A2i",
        symptom: "H/I 9. Often interrupts or intrudes on others",
        presentMaturity: "",
        presentChildhood: ""
    },
];