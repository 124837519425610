import React, {useState} from 'react';
import {Text} from '../../components/Text/Text';
import {Await, defer, useFetcher, useLoaderData, useParams, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {Spinner} from '../../components/Spinner/Spinner';
import dayjs from 'dayjs';
import {ChevronIcon, EditIcon, Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowButton, TableRowCell, TableRowInner, TrashIcon} from '../../components/Table/Table';
import {deleteData, fetchProtectedData, postData} from '../../api/fetch';
import {AddQuestionnaire} from './Partials/AddQuestionnaire';
import {DeleteQuestionnaire} from './Partials/DeleteQuestionnaire';
import {useOpenModal} from '../../hooks/useOpenModal';
import {LayeredCustomLink} from '../../components/CustomLink/CustomLink';
import Toggle from "../../components/Toggle/Toggle";
import {checkAnyRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import {checkAnyAccountPermissions} from '../../utils/helpers';

const Header = styled.div`
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-end;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

const CompleteAll = styled.span`
    font-size: var(--fs-search-button);
    margin-left: 15px;
    color: var(--color-blue-50);
    font-weight: var(--fw-regular);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export async function questionnairesAction({request, params}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch (intent) {
        case "add-questionnaire": {
            const body = {
                "questionnaireId": formData.get("questionnaire"),
            }

            return await postData(request, `patient/${params.patientUUID}/questionnaire`, JSON.stringify(body));
        }

        case "delete-questionnaire": {
            const questionnaireId = formData.get("questionnaireId");

            return await deleteData(request, `patient/${params.patientUUID}/questionnaire/${questionnaireId}`);
        }

        case 'complete-questionnaires': {
            return await postData(request, `patient/${params.patientUUID}/questionnaire/complete`, formData.get('body'));
        }

        default:
            return {"default": true};
    }
}

export async function questionnairesLoader({request, params}) {
    await checkAnyRequiredAccount([
        Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_VIEW,
        Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_VIEW,
        Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_VIEW
    ]);

    const questionnairePromise = fetchProtectedData(request, `patient/${params.patientUUID}/questionnaire`);

    return defer({questionnairePromise});
}

const Questionnaires = () => {
    const {account} = useRouteLoaderData("root");
    // const hasRoleLa = useRoles().includes('desk-medewerkers');
    // const hasRolePractitioner = useRoles().includes('behandelaars');
    const fetcher = useFetcher()
    const {questionnairePromise} = useLoaderData();
    const {patientUUID} = useParams();

    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [targetId, setTargetId] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleDeleteButton = (id) => {
        setTargetId(id);
        handleOpen();
    }

    const toggleCompleteQuestionnaire = async (id, newState, setLoading, toggleAll = false, questionnaireIds = []) => {
        const formData = new FormData()
        formData.set('intent', 'complete-questionnaires')
        const body = [];

        if (toggleAll === true) {
            questionnaireIds.forEach(id => {
                body.push({"id": id, "completedWithoutResponse": true})
            });
        } else {
            body.push({"id": id, "completedWithoutResponse": newState})
        }

        formData.append('body', JSON.stringify(body))

        fetcher.submit(formData, { method: "POST", action: `/patient/${patientUUID}/vragenlijsten`});
    }

    return (
        <>
            {checkAnyAccountPermissions(account, [
                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_CREATE,
                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_CREATE,
                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_CREATE,
                ]) &&
                <Header>
                    <AddQuestionnaire/>
                </Header>
            }

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner/>
                </SpinnerWrapper>
            }>
                <Await resolve={questionnairePromise} errorElement={<Text>De vragenlijsten kunnen niet worden opgehaald. Probeer het opnieuw.</Text>}>
                    {(questionnaireData) => {
                        if (questionnaireData.length === 0) return <Text>Er zijn nog geen vragenlijsten toegevoegd.</Text>

                        const showCompleteWithoutResponseColumn = () => {
                            return questionnaireData.some((item) => item.canCompleteWithoutResponse === true)
                        }

                        const canDeleteAny = () => {
                            return questionnaireData.some((item) => item.canDelete) && checkAnyAccountPermissions(account, [
                                Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_DELETE,
                                Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_DELETE,
                                Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_DELETE,
                            ])
                        }
                        const canViewOrEditAny = () => {
                            return questionnaireData.some((item) => (item.canRespond &&
                                checkAnyAccountPermissions(account, [
                                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_VIEW,
                                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_VIEW,
                                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_VIEW,
                                ])) ||
                                checkAnyAccountPermissions(account, [
                                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_EDIT,
                                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_EDIT,
                                    Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_EDIT,
                                ]))
                        }

                        const questionnaireIds = questionnaireData.filter((item) => item.completedWithoutResponse === false).map(item => {
                            return item.id
                        })

                        return (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableHeadRow>
                                            <TableHeadCell>Vragenlijst</TableHeadCell>
                                            <TableHeadCell>Datum uitgenodigd</TableHeadCell>
                                            <TableHeadCell>Datum ingevuld</TableHeadCell>
                                            {showCompleteWithoutResponseColumn() &&
                                                <TableHeadCell $flex="0" $minWidth="200px"><span>Voltooid <CompleteAll
                                                    onClick={() => toggleCompleteQuestionnaire(null, false, setLoading, true, questionnaireIds)}>Voltooi alle</CompleteAll></span></TableHeadCell>
                                            }
                                            {canDeleteAny() && <TableHeadCell $flex="0" $padding="0 0 15px 0" $minWidth="48px"></TableHeadCell>}
                                            {canViewOrEditAny() && <TableHeadCell $flex="0" $padding="0 0 15px 0" $minWidth="48px"></TableHeadCell>}
                                        </TableHeadRow>
                                    </TableHead>

                                    {questionnaireData.map((item, index) => {
                                        const isInactive = !Boolean(item.active);


                                        return (
                                            <TableRow key={item.id} $isClickable={(
                                                checkAnyAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${item.classification.toUpperCase()}_READ`) ||
                                                checkAnyAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${item.classification.toUpperCase()}_EDIT`)
                                            ) && !isInactive}>
                                                <TableRowInner $isOddRow={index % 2 === 0}>
                                                    <TableRowCell $variant="title" $isGrayedOut={isInactive}>{item.title}</TableRowCell>
                                                    <TableRowCell $isGrayedOut={isInactive}>{item.start && dayjs(item.start).format("DD-MM-YYYY")}</TableRowCell>
                                                    <TableRowCell $isGrayedOut={isInactive}>{item.completedAt !== null && dayjs(item.completedAt).format("DD-MM-YYYY HH:mm")}</TableRowCell>
                                                    {showCompleteWithoutResponseColumn() &&
                                                        <TableRowCell $flex="0" $minWidth="200px">
                                                            {item.canCompleteWithoutResponse && (
                                                                <TableRowButton>
                                                                    <Toggle
                                                                        id={item.id}
                                                                        initialValue={item.completedWithoutResponse}
                                                                        key={item.id}
                                                                        checked={item.completedWithoutResponse}
                                                                        onChange={(id, newState) => toggleCompleteQuestionnaire(id, newState, setLoading)}
                                                                        disabled={loading || isInactive}
                                                                    />
                                                                </TableRowButton>
                                                            )}
                                                        </TableRowCell>
                                                    }
                                                    {canDeleteAny() &&
                                                        <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                            {(checkAnyAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${item.classification.toUpperCase()}_DELETE`)) && item.canDelete &&
                                                                <TableRowButton onClick={() => handleDeleteButton(item.id)}><TrashIcon/></TableRowButton>
                                                            }
                                                        </TableRowCell>
                                                    }
                                                    {canViewOrEditAny() &&
                                                        <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                            {(item.canRespond && checkAnyAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${item.classification.toUpperCase()}_EDIT`) && item.completedAt == null && !isInactive)
                                                                ? <LayeredCustomLink to={`/patient/${patientUUID}/vragenlijst/${item.id}/invullen`}><EditIcon/></LayeredCustomLink>
                                                                : (checkAnyAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${item.classification.toUpperCase()}_READ`) && !isInactive) ?
                                                                    <LayeredCustomLink to={`/patient/${patientUUID}/vragenlijst/${item.id}`}><ChevronIcon/></LayeredCustomLink> : <></>
                                                            }
                                                        </TableRowCell>
                                                    }
                                                </TableRowInner>
                                            </TableRow>
                                        );
                                    })}
                                </Table>

                                <DeleteQuestionnaire isOpen={isOpen} handleClose={handleClose} item={targetId}/>
                            </>
                        );
                    }}
                </Await>
            </React.Suspense>
        </>
    );
}

export default Questionnaires;