import React, {useRef} from 'react';
import {Outlet, useLoaderData, useLocation, useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {deleteData, postData} from '../../api/fetch';
import {AddNotification} from "./Partials/AddNotification";
import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import {checkAccountPermissions} from '../../utils/helpers';
import SegmentedControl from '../../components/SegmentedControl/SegmentedControl';

const Header = styled.div`
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
`;

export async function notificationsAction({request, params}) {
    let formData = await request.json();
    let intent = formData.intent

    switch(intent) {
        case "add-notification": {
            delete formData.intent
            return await postData(request, `patient/${params.patientUUID}/notification`, JSON.stringify(formData));
        }

        case "delete-notification": {
            const notificationId = formData.get("notificationId");

            return await deleteData(request, `patient/${params.patientUUID}/notification/${notificationId}`);
        }

        default:
            return {"default": true};
    }
}

export async function notificationsLoader() {
    const {account} = await checkRequiredAccount(Permissions.NOTIFICATION_VIEW);

    return {account};
}

const Notifications = () => {
    const {account} = useLoaderData();
    const {patientUUID} = useParams();
    const navigate = useNavigate();
    const {pathname, search} = useLocation();

    return (
        <>
            <Header>
                <SegmentedControl
                    name="notifications"
                    callback={(pathname) => {
                        const params = new URLSearchParams(search)
                        params.delete("page")
                        navigate(`${pathname}?${params ? params.toString() : ''}`, {preventScrollReset: true})
                    }}
                    defaultIndex={pathname.includes("ingepland") ? 1 : 0}
                    controlRef={useRef()}
                    segments={[
                        {
                            label: "Verstuurd",
                            value: `/patient/${patientUUID}/berichten`,
                            ref: useRef()
                        },
                        {
                            label: "Ingepland",
                            value: `/patient/${patientUUID}/berichten/ingepland`,
                            ref: useRef()
                        }
                    ]}
                />

                {checkAccountPermissions(account, Permissions.NOTIFICATION_CREATE) &&
                    <AddNotification />
                }
            </Header>

            <Outlet />
        </>
    );
}

export default Notifications;