import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Cross} from "../../../assets/icons/white-cross.svg";
import {ReactComponent as Hourglass} from "../../../assets/icons/hourglass.svg";
import {ReactComponent as Check} from "../../../assets/icons/radio-check.svg";
import {NotificationStatuses} from "../../../constants/enums";

const NotificationStatusRoot = styled.div`
    background-color: ${({$backgroundColor}) => $backgroundColor ?? 'none'};
    width: 32px;
    height: 32px;
    border-radius: 6.4px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const matchStatus = (status, cases) => cases.includes(status)

function notificationStyle(status) {
    switch (true) {
        case matchStatus(status, [NotificationStatuses.Delivered, NotificationStatuses.Click, NotificationStatuses.Open]):
            return {icon: <Check/>, backgroundColor: "var(--color-notification-status-delivered)"}
        case matchStatus(status, [NotificationStatuses.Processed]):
            return {icon: <Hourglass/>, backgroundColor: "var(--color-notification-status-pending)"}
        case matchStatus(status, [NotificationStatuses.Dropped, NotificationStatuses.Bounce, NotificationStatuses.SpamReport]):
            return {icon: <Cross/>, backgroundColor: "var(--color-required)"}
        default:
            return {icon: "", backgroundColor: ""}
    }
}

export const NotificationStatus = ({status}) => {
    const {icon, backgroundColor} = notificationStyle(status)

    if (!icon && !backgroundColor) return null;

    return (
        <NotificationStatusRoot $backgroundColor={backgroundColor}>{icon}</NotificationStatusRoot>
    );
}
