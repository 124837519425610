import styled from 'styled-components';
import {Text} from '../Text/Text';
import {Checkbox} from '../Input/Input';

const StyledCheckboxField = styled.div``;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
`;

const CheckboxLabel = styled(Text).attrs({
    as: "label"
})`
    display: inline-block;
`;

export const CheckboxField = ({data, ...props}) => {
    return (
        <StyledCheckboxField>
            <CheckboxWrapper key={data.id}>
                <Checkbox
                    type="checkbox"
                    defaultChecked={data.answer}
                    disabled={props.disabled}
                    {...(props.register && props.register(props.name, {
                        required: props.required,
                        onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                    }))}
                />
                <CheckboxLabel $checked={data.answer}>{data.label}</CheckboxLabel>
            </CheckboxWrapper>
        </StyledCheckboxField>
    );
}