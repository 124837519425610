import {checkRequiredAccount} from '../Root/Root';
import {InfoList, InfoListItem, InfoListWrapper} from '../../components/InfoList/InfoList';
import {redirect, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {Alert} from '../../components/Alert/Alert';
import {CareTeam} from './Partials/CareTeam';
import {Features, Permissions} from '../../constants/enums';
import {hasFeatureAccess, joinWithCommaAndAmpersand} from '../../utils/helpers';

const StyledInfoListWrapper = styled(({$hasTimelineFeature = true, ...props}) => (
    <InfoListWrapper $hasTimelineFeature={$hasTimelineFeature} {...props} />
))`
    grid-template-columns: 1fr 1fr 0.75fr 1fr;
`;

export async function summaryLoader({request, params}) {
    try {
        const {account} = await checkRequiredAccount(Permissions.PORTAL_ACCESS_PATIENT_SUMMARY);
        if (!hasFeatureAccess(account, Features.TIMELINE)) return redirect(`/patient/${params.patientUUID}/vragenlijsten`)
        return account;
    } catch (error) {
        return redirect(`/patient/${params.patientUUID}/personalia`)
    }
}

const Summary = () => {
    const {patientData} = useRouteLoaderData("patient");

    return (
        <>
            <StyledInfoListWrapper $hasTimelineFeature={true}>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Locatie">{patientData?.location?.[0] && patientData.location[0]}</InfoListItem>
                    <InfoListItem label="Status">{patientData.statuses?.[0]?.title}</InfoListItem>
                    <InfoListItem hide={true} label="Comorbiditeit">{patientData.comorbidity}</InfoListItem>
                </InfoList>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Primaire diagnose">{patientData.primaryDiagnosis}</InfoListItem>
                    <InfoListItem label="Zorgvraagtype">{patientData.careDemandType}</InfoListItem>
                    <InfoListItem label="Start behandeling">{patientData.startCarePlan}</InfoListItem>
                </InfoList>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Behandeltraject">{joinWithCommaAndAmpersand(patientData.carePlan)}</InfoListItem>
                    <CareTeam/>
                    <InfoListItem hide={true} label="Hartslag">{patientData.heartRate}</InfoListItem>
                    <InfoListItem hide={true} label="Bloeddruk">{patientData.bloodPressure}</InfoListItem>
                </InfoList>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Aantal no-shows" wrapInText={false}>{patientData.noShows && <Alert>{patientData.noShows}</Alert>}</InfoListItem>
                    <InfoListItem hide={true} label="Medicatie">{patientData.medication}</InfoListItem>
                    <InfoListItem hide={true} label="Medische bijzonderheden">{patientData.medicalDetails}</InfoListItem>
                </InfoList>
            </StyledInfoListWrapper>
        </>
    );
}

export default Summary;