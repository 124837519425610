import React, {useRef} from "react";
import {Permissions} from "../../constants/enums";
import {Heading1} from "../../components/Heading/Heading";
import {fetchProtectedData} from '../../api/fetch';
import {defer, Outlet, useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import {checkRequiredAccount} from '../Root/Root';
import SegmentedControl from '../../components/SegmentedControl/SegmentedControl';
import styled from 'styled-components';

const PageHeading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 56px;
`;

export async function notificationCenterMessagesLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_VIEW]);

    const healthInsuranceData = await fetchProtectedData(request, 'health-insurance');

    return defer({healthInsuranceData});
}

const NotificationCenterMessages = () => {
    const {healthInsuranceData} = useLoaderData();
    const navigate = useNavigate();
    const {pathname, search} = useLocation();

    return (
        <>
            <PageHeading>
                <Heading1>Verstuurde berichten</Heading1>
                <SegmentedControl
                    name="notifications"
                    callback={(pathname) => {
                        const params = new URLSearchParams(search)
                        params.delete("page")
                        navigate(`${pathname}?${params ? params.toString() : ''}`, { preventScrollReset: true })
                    }}
                    defaultIndex={pathname.includes("ingepland") ? 1 : 0}
                    controlRef={useRef()}
                    segments={[
                        {
                            label: "Verstuurd",
                            value: "/berichtencentrum/verstuurde-berichten",
                            ref: useRef()
                        },
                        {
                            label: "Ingepland",
                            value: "/berichtencentrum/verstuurde-berichten/ingepland",
                            ref: useRef()
                        }
                    ]}
                />
            </PageHeading>

            <Outlet context={{healthInsuranceData}} />
        </>
    )
}

export default NotificationCenterMessages;