import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {useSearchParams} from 'react-router-dom';
import {PortalDateTimeField} from '../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField';
import {PortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/PortalSelectField';
import dayjs from 'dayjs';
import {FilterButtonBase} from '../../../components/FilterButtonBase/FilterButtonBase';
import {ControlledPortalSelectField} from "../../../components/PortalFormFields/PortalSelectField/ControlledPortalSelectField";

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    min-width: 0;
`;

export function FilterButton({healthInsuranceData, onAfterSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Set the unappliedFilters initially to current searchParams (retreived from searchParams)
    const [unappliedFilters, setUnappliedFilters] = useState({
        nextAppointmentFrom: searchParams.get("nextAppointmentFrom"),
        nextAppointmentUntil: searchParams.get("nextAppointmentUntil"),
        healthInsurance: searchParams.get("healthInsurance"),
        hasUncompletedQuestionnaires: searchParams.get("hasUncompletedQuestionnaires"),
    });

    // Keep unappliedFilters correct when searchParams change (when deleting in AppliedFilterList)
    useEffect(() => {
        setUnappliedFilters({
            nextAppointmentFrom: searchParams.get("nextAppointmentFrom"),
            nextAppointmentUntil: searchParams.get("nextAppointmentUntil"),
            healthInsurance: searchParams.getAll("healthInsurance"),
            hasUncompletedQuestionnaires: searchParams.get("hasUncompletedQuestionnaires"),
        })
    }, [searchParams]);

    function handleUnappliedFiltersChange(key, value) {
        setUnappliedFilters({
            ...unappliedFilters,
            [key]: value
        })
    }

    function handleApply() {
        // Set search params optionally
        const newParams = {
            ...(unappliedFilters.nextAppointmentFrom ? {nextAppointmentFrom: unappliedFilters.nextAppointmentFrom} : {}),
            ...(unappliedFilters.nextAppointmentUntil ? {nextAppointmentUntil: unappliedFilters.nextAppointmentUntil} : {}),
            ...(unappliedFilters.healthInsurance ? {healthInsurance: unappliedFilters.healthInsurance} : {}),
            ...(unappliedFilters.hasUncompletedQuestionnaires ? {hasUncompletedQuestionnaires: unappliedFilters.hasUncompletedQuestionnaires} : {}),
        }
        setSearchParams(newParams, { replace: true, preventScrollReset: true });
        onAfterSubmit();
        setIsOpen(false);
    }

    // Select fields options
    const healthInsuranceFilterOptions = healthInsuranceData?.map(item => ({value: item.id, label: item.name})) ?? [];

    const hasUncompletedQuestionnairesFilterOptions = [
        { value: "", label: "Alle" },
        { value: "1", label: "Ja" },
        { value: "0", label: "Nee" },
    ];

    return (
        <FilterButtonBase isOpen={isOpen} setIsOpen={setIsOpen} handleApply={handleApply}>
            <StyledFormFieldWrapper
                id="nextAppointmentFrom"
                label="Eerstkomende afspraak vanaf"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    hideTime={true}
                    defaultValue={unappliedFilters?.nextAppointmentFrom ? dayjs(unappliedFilters.nextAppointmentFrom).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("nextAppointmentFrom", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="nextAppointmentUntil"
                label="Eerstkomende afspraak vóór"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    hideTime={true}
                    defaultValue={unappliedFilters?.nextAppointmentUntil ? dayjs(unappliedFilters.nextAppointmentUntil).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("nextAppointmentUntil", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="healthInsurance"
                label="Zorgverzekeraar"
            >
                <ControlledPortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={healthInsuranceFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("healthInsurance", option?.filter(o => o?.value?.length > 0).map(o => o?.value?.toLowerCase()))}
                    defaultValue={healthInsuranceFilterOptions?.filter(o => unappliedFilters?.healthInsurance?.includes(o?.value?.toLowerCase()))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="hasUncompletedQuestionnaires"
                label="Openstaande vragenlijsten?"
            >
                <PortalSelectField
                    options={hasUncompletedQuestionnairesFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("hasUncompletedQuestionnaires", option?.value)}
                    defaultValue={hasUncompletedQuestionnairesFilterOptions?.find(item => item?.value === unappliedFilters?.hasUncompletedQuestionnaires) ?? hasUncompletedQuestionnairesFilterOptions[0]}
                />
            </StyledFormFieldWrapper>
        </FilterButtonBase>
    );
}