import styled, {css} from 'styled-components';
import {Heading2, SubHeading} from '../Heading/Heading';
import {ReactComponent as Chevron} from '../../assets/icons/chevron.svg';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';
import {ReactComponent as Edit} from '../../assets/icons/edit.svg';
import {ReactComponent as Filter} from '../../assets/icons/filter.svg';
import {Text} from '../Text/Text';

const flexMixin = css`
    ${({$flex}) => !$flex && css`
        flex-grow: ${({$flexGrow}) => $flexGrow || 1};
        flex-shrink: ${({$flexShrink}) => $flexShrink || 1};
        flex-basis: ${({$flexBasis}) => $flexBasis || '0'};
        min-width: ${({$minWidth}) => $minWidth || '200px'};
    `}

    ${({$flex}) => $flex && css`
        flex: ${$flex};
        min-width: ${({$minWidth}) => $minWidth || 'unset'};
    `}
`;

export const Table = styled.div`
    overflow-x: ${({$overflow}) => $overflow ?? "auto"};
    width: 100%;
`;

export const TableHead = styled.div`
    display: grid;
`;

export const TableHeadRow = styled.div`
    display: flex;
    width: 100%;
`;

export const TableHeadCell = styled(Heading2).attrs({
    as: "div"
})`
    padding: ${({$padding}) => $padding ?? '0 20px 15px 20px'};
    font-size: ${({ $fontSize }) => $fontSize || 'auto'};
    min-width: ${({$minWidth}) => $minWidth ?? 'unset'};
    max-width: ${({$maxWidth}) => $maxWidth ?? 'unset'};
    ${flexMixin};
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    
    ${({$isGrayedOut}) => $isGrayedOut && 'color: var(--color-text-alpha-50);'};
`;

export const TableHeadCellSubtitle = styled(SubHeading).attrs({
    as: "span"
})`
    ${({$isGrayedOut}) => $isGrayedOut && 'color: var(--color-text-alpha-50);'};
`;

export const TableRowInner = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 10px;
    background-color: ${({$isOddRow, $disabled, $transparent}) => $isOddRow ? ($disabled ? 'var(--color-table-odd-row-disabled)' : 'var(--color-table-odd-row)') : $transparent ? 'transparent' : 'var(--color-content-background)'};
    color: inherit;
    text-decoration: none;
    min-height: 64px;
    ${({$deleted}) => $deleted && "text-decoration: line-through;"};
`;

export const TableRowCell = styled(Text).attrs({
    as: "div"
})`
    display: flex;
    padding: ${({$padding}) => $padding ?? '16px 20px'};
    margin: auto 0;
    color: var(--color-table-text);
    font-weight: var(--fw-regular);
    justify-content: ${({$justifyContent}) => $justifyContent ?? 'normal'};
    ${({$alignItems}) => $alignItems && `align-items: ${$alignItems}`};
    ${({$gap}) => $gap && `gap: ${$gap}`};
    min-width: ${({$minWidth}) => $minWidth ?? 'unset'};
    max-width: ${({$maxWidth}) => $maxWidth ?? 'unset'};
    ${flexMixin};
    ${({$deleted}) => $deleted && 'opacity: .3;'};

    ${({$variant}) => $variant === "title" && css`
        color: var(--color-table-title);
        font-weight: var(--fw-bold);
    `};
    
    ${({$variant}) => $variant === "total" && css`
        color: var(--color-table-title);
        font-weight: var(--fw-extra-bold);
        font-size: var(--fs-heading-2);
    `};

    ${({$isGrayedOut}) => $isGrayedOut && 'color: var(--color-text-alpha-50);'};
`;

export const TableRow = styled.div`
    display: grid;
    
    ${({$isClickable}) => $isClickable && css`
        &:hover ${TableRowCell} {
            cursor: pointer;
            color: var(--color-text-alpha-50);
        }
    `}; 
`;

const IconStyles = css`
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
`;

export const TableRowButton = styled.button`
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    
    &:disabled {
        opacity: 0.6;
    }
`;

export const ChevronIcon = styled(Chevron)`
    ${IconStyles};
    
    path { fill: var(--color-blue-50); }
`;

export const FilterIcon = styled(Filter)`
    ${IconStyles};
    z-index: 20;
    position: relative;
`

export const TrashIcon = styled(Trash)`
    ${IconStyles};
`;

export const EditIcon = styled(Edit)`
    ${IconStyles};
`;

export const TableRegisterButton = styled.button`
    cursor: pointer;
    border: none;
    background: transparent;
    font-weight: bold;
    color: var(--color-primary);
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;