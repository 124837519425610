import styled from 'styled-components';
import {ChevronIcon, TableRow, TableRowCell, TableRowInner} from '../../../components/Table/Table';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import dayjs from "dayjs";

const StyledTableRowInner = styled(TableRowInner)`
    cursor: pointer;
    display: grid;
    grid-template-columns: 52px minmax(200px, 1fr) repeat(6, 1fr) 52px; // Make sure this is the same as QuestionnaireOverview's StyledTableHeadRow
`;

const StyledChevronIcon = styled(ChevronIcon)`
    transform: rotate(${({$isOpen}) => $isOpen ? "-90deg" : "90deg"});
    transition: transform .2s ease;
`;

const PatientLink = styled(Link)`
    color: var(--color-blue-50);
    font-weight: var(--fw-bold);
    text-decoration: underline;
    cursor: pointer;
`;

const Block = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    font-weight: var(--fw-extra-bold);
    border-radius: 7px;
    width: 80px;
    text-align: center;
    background-color: ${({$everyQuestionnaireIsCompleted, $remainingDays}) =>
        $everyQuestionnaireIsCompleted ? "var(--color-remaining-block-green)"
        : $remainingDays <= 7 ? "var(--color-remaining-block-red)"
        : $remainingDays <= 14 ? "var(--color-remaining-block-orange)"
        : "var(--color-remaining-block-green)"};
`;

const CollapsibleContent = styled.div`
    overflow: hidden;
    opacity: ${({$isOpen}) => $isOpen ? "1" : "0"};
    width: 100%;
    ${({$height}) => `max-height: ${$height}px`};
    transition: max-height .2s ease, opacity .2s ease;
`;

const CollapsibleContentInner = styled.div`
    padding: 10px 50px 12px 50px;
`;

const SubTableRowInner = styled(TableRowInner)`
    display: grid;
    grid-template-columns: minmax(200px, 1fr) repeat(5, 1fr);
    border-radius: 0;
`;

const SubTable = styled.div`
    border: 2px solid var(--color-divider);
    border-radius: 10px;
    overflow: hidden;
`;

const StyledTableRowCell = styled(TableRowCell)`
    grid-column: span 2;
`;

const TitleTableRowCell = styled(TableRowCell)`
    grid-column: span 3;
`;

export const CollapsibleTableRow = ({item, isOpen, setActiveIndex, isOddRow}) => {
    const [height, setHeight] = useState(0);
    const content = useRef(null);
    const hasQuestionnaires = Boolean(item?.questionnaires?.length);

    useEffect(() => {
        const handleOpen = () => {
            content.current.style.overflow = "unset";
            setHeight(content.current.scrollHeight);
        }

        const handleClose = () => {
            content.current.style.overflow = "hidden";
            setHeight(0);
        }

        isOpen ? handleOpen() : handleClose();
    }, [isOpen]);

    return (
        <>
            <TableRow>
                <StyledTableRowInner $isOddRow={isOddRow} onClick={() => hasQuestionnaires ? setActiveIndex(isOpen ? null : item.id) : undefined}>
                    <TableRowCell $flex="0" $minWidth="52px" $padding="16px 0 16px 20px">
                        {hasQuestionnaires &&
                            <StyledChevronIcon $isOpen={isOpen}/>
                        }
                    </TableRowCell>
                    <TableRowCell $minWidth="200px">
                        <PatientLink to={{pathname: `/patient/${item.patient.id}`}} onClick={(e) => e.stopPropagation()}>
                            {item.patient.name && item.patient.name}
                        </PatientLink>
                    </TableRowCell>
                    <TableRowCell $minWidth="0">{item.patient.medicoreID && item.patient.medicoreID}</TableRowCell>
                    <TableRowCell $minWidth="0">{item.appointment?.internalDate && dayjs(item.appointment?.internalDate).format("DD-MM-YYYY")}</TableRowCell>
                    <TableRowCell $minWidth="0">{item.appointment.location?.name}</TableRowCell>
                    <TableRowCell $minWidth="0">{item.patient.hasAppUser ? "Ja" : "Nee"}</TableRowCell>
                    <TableRowCell $minWidth="0">{item.status && item.status}</TableRowCell>
                    <StyledTableRowCell $minWidth="0">
                        <Block
                            $everyQuestionnaireIsCompleted={item?.questionnaires?.every(item => item?.status?.startsWith("Ingevuld") || item?.status?.startsWith('Voltooid'))}
                            $remainingDays={item.appointment?.remainingDays}
                        >
                            {item.appointment?.remainingDays}
                        </Block>
                    </StyledTableRowCell>
                </StyledTableRowInner>
            </TableRow>

            <CollapsibleContent ref={content} $height={height} $isOpen={isOpen}>
                <CollapsibleContentInner>
                    <SubTable>
                        {item?.questionnaires?.map((item, rowIndex) => {
                            const isOddRow = rowIndex % 2 === 0;

                            return (
                                <TableRow key={item.id}>
                                    <SubTableRowInner $isOddRow={isOddRow}>
                                        <TitleTableRowCell $minWidth="0" $variant="title">{item.title}</TitleTableRowCell>
                                        <TableRowCell $minWidth="0">{item.start}</TableRowCell>
                                        <StyledTableRowCell $minWidth="0">{item.status}</StyledTableRowCell>
                                    </SubTableRowInner>
                                </TableRow>
                            );
                        })}
                    </SubTable>
                </CollapsibleContentInner>
            </CollapsibleContent>
        </>
    );
}