import {fetchProtectedData} from '../../api/fetch';
import {defer, useLoaderData} from 'react-router-dom';
import {ViewAnswersModal} from '../../components/Modal/ViewAnswersModal';
import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';

export async function answersLoader({request, params}) {
    const answersPromise = fetchProtectedData(request, `patient/${params.patientUUID}/questionnaire/${params.questionnaireUUID}/answers`);

    return defer({answersData: await answersPromise});
}

const Answers = () => {
    const {answersData} = useLoaderData();

    return (
        <ViewAnswersModal answersData={answersData} />
    );
}

export default Answers;