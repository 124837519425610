import styled from 'styled-components';
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../Table/Table';

const StyledDivaSummaryTable = styled.div`
    margin-top: 50px;
`;

export const DivaSummaryTable = ({summaryPart1, summaryPart2, disabled, isDivaEnglish}) => {
    const answerYes = isDivaEnglish ? "Yes" : "Ja"
    const criterion = isDivaEnglish ? "Criterion" : "Criterium"
    const symptom = isDivaEnglish ? <p>Symptom</p> : <p>Symptoom</p>
    const presentAdulthood = isDivaEnglish ? <p>Present during adulthood</p> : <p>Aanwezig volwassen&shy;heid</p>
    const presentChildhood = isDivaEnglish ? <p>Present during childhood</p> : <p>Aanwezig kinder&shy;tijd</p>
    const totalAttentionDeficit = isDivaEnglish ? <p>Total number of criteria Attention Deficit</p> : <p>Totaal aantal criteria Aandachtsdeficiëntie</p>
    const totalHyperactivitiy = isDivaEnglish ? <p>Total number of criteria Hyperactivity/Impulsivity</p> : <p>Totaal aantal criteria Hyperactiviteit/Impulsiviteit</p>


    return (
        <StyledDivaSummaryTable>
            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableHeadCell $flexGrow={undefined} $minWidth="unset">{criterion}<br/>DSM-5</TableHeadCell>
                        <TableHeadCell $flexGrow={3} $minWidth="unset">{symptom}</TableHeadCell>
                        <TableHeadCell $flexGrow={undefined} $minWidth="unset">{presentAdulthood}</TableHeadCell>
                        <TableHeadCell $flexGrow={undefined} $minWidth="unset">{presentChildhood}</TableHeadCell>
                    </TableHeadRow>
                </TableHead>

                {/* Part 1 */}
                {summaryPart1.map((item, index) => (
                    <TableRow key={item.id}>
                        <TableRowInner $isOddRow={index % 2 === 0} $disabled={disabled}>
                            <TableRowCell $variant="title" $flexGrow={undefined} $minWidth="unset">{item.criterion}</TableRowCell>
                            <TableRowCell $flexGrow={3} $minWidth="unset">{item.symptom}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentMaturity}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentChildhood}</TableRowCell>
                        </TableRowInner>
                    </TableRow>
                ))}
                <TableRow>
                    <TableRowInner>
                        <TableRowCell $variant="total" $flexGrow={3} $minWidth="unset">{totalAttentionDeficit}</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset"></TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart1?.filter(item => item.presentMaturity === answerYes)?.length} / 9</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart1?.filter(item => item.presentChildhood === answerYes)?.length} / 9</TableRowCell>
                    </TableRowInner>
                </TableRow>

                {/* Part 2 */}
                {summaryPart2.map((item, index) => (
                    <TableRow key={item.id}>
                        <TableRowInner $isOddRow={index % 2 === 0} $disabled={disabled}>
                            <TableRowCell $variant="title" $flexGrow={undefined} $minWidth="unset">{item.criterion}</TableRowCell>
                            <TableRowCell $flexGrow={3} $minWidth="unset">{item.symptom}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentMaturity}</TableRowCell>
                            <TableRowCell $flexGrow={undefined} $minWidth="unset">{item.presentChildhood}</TableRowCell>
                        </TableRowInner>
                    </TableRow>
                ))}
                <TableRow>
                    <TableRowInner>
                        <TableRowCell $variant="total" $flexGrow={3} $minWidth="unset">{totalHyperactivitiy}</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset"></TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart2?.filter(item => item.presentMaturity === answerYes)?.length} / 9</TableRowCell>
                        <TableRowCell $variant="total" $flexGrow={undefined} $minWidth="unset">{summaryPart2?.filter(item => item.presentChildhood === answerYes)?.length} / 9</TableRowCell>
                    </TableRowInner>
                </TableRow>
            </Table>
        </StyledDivaSummaryTable>
    )
}