import React, {useEffect} from 'react';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Await, defer, NavLink, Outlet, useLoaderData, useParams, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {fetchProtectedData} from '../../api/fetch';
import {ReactComponent as Medicore} from '../../assets/medicore-icon.svg';
import {checkRequiredAccount} from '../Root/Root';
import {calculateAge, checkAccountPermissions, checkAnyAccountPermissions, hasFeatureAccess} from '../../utils/helpers';
import {Features, Permissions} from '../../constants/enums';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {CopyButton} from '../../components/CopyButton/CopyButton';
import {SyncButton} from './Partials/SyncButton';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {BackButton} from '../../components/BackButton/BackButton';
import {getHomePage} from '../Login/Login';
import {Timeline} from '../../components/Timeline/Timeline';
import {Spinner} from '../../components/Spinner/Spinner';
import {InfoList, InfoListWrapper} from "../../components/InfoList/InfoList";
import {EditIcon, TrashIcon} from "../../components/Table/Table";
import {useOpenModal} from "../../hooks/useOpenModal";
import {DeleteAppAccount} from "../PersonalDetails/Partials/DeleteAppAccount";
import {AddAppAccount} from "../PersonalDetails/Partials/AddAppAccount";

const StyledBackButton = styled(BackButton)`
    margin-top: 10px;
    margin-bottom: 26px;
    color: var(--color-primary);

    &:hover {
        color: var(--color-primary-hover);
    }
`;

const Tabs = styled.div`
    display: flex;
    gap: 4px;
    margin: 50px 0 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--color-divider);
`;

const Tab = styled.button`
    background-color: ${({$isActive}) => $isActive ? 'var(--color-tab)' : 'transparent'};
    color: var(--color-text);
    border-radius: 30px;
    cursor: pointer;
    padding: 8px 20px;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    text-align: center;
    border: none;

    &:hover {
        background-color: var(--color-tab);
    }
`;

const FlexHeading = styled.header`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
`;

const PatientID = styled(Text).attrs({
    $noMargin: true,
    $small: true
})`
    margin-bottom: 6px;
`;

const PatientName = styled(Heading1).attrs({
    $noMargin: true,
})`
    margin-bottom: 10px;
`;

const PatientActions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        display: block;
    }
`;

const TimelineWrapper = styled.div`
    margin: 30px 0 0;
`;

const TimelineFallback = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 291px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`;

const PrivateInfoListItem = styled(Text).attrs({
    $noMargin: true,
})`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export async function patientLoader({request, params}) {
    const {account} = await checkRequiredAccount(Permissions.PATIENT_READ);

    const patientPromise = fetchProtectedData(request, `patient/${params.patientUUID}`);
    const timelinePromise = hasFeatureAccess(account, Features.TIMELINE) ? fetchProtectedData(request, `patient/${params.patientUUID}/timeline`) : null;

    return defer({patientData: await patientPromise, timelinePromise});
}

const Patient = () => {
    const {account} = useRouteLoaderData("root");
    const {patientData, timelinePromise} = useLoaderData();
    const {patientUUID} = useParams();
    const hasTimelineFeature = hasFeatureAccess(account, Features.TIMELINE)
    const {isOpen: isOpenDelete, handleOpen: handleOpenDelete, handleClose: handleCloseDelete} = useOpenModal();
    const {isOpen: isOpenAdd, handleOpen: handleOpenAdd, handleClose: handleCloseAdd} = useOpenModal();

    const tabs = [
        ...(hasTimelineFeature ? [
            checkAccountPermissions(account, Permissions.PORTAL_ACCESS_PATIENT_SUMMARY) && {
                title: "Samenvatting",
                slug: `/patient/${patientUUID}`,
                end: true,
            },
            checkAccountPermissions(account, Permissions.PATIENT_VIEW) && {
                title: "Personalia",
                slug: "personalia",
                end: true,
            },
        ].filter(Boolean) : []),
        checkAnyAccountPermissions(account, [
            Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_GENERAL_VIEW,
            Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_SCREENING_VIEW,
            Permissions.PATIENTQUESTIONNAIRE_CLASSIFICATION_MEDICAL_VIEW]) &&
        {
            title: "Vragenlijsten",
            slug: "vragenlijsten",
            end: true,
        },
        checkAccountPermissions(account, Permissions.DOCUMENT_VIEW) && {
            title: "Documenten",
            slug: "documenten",
            end: true,
        },
        checkAccountPermissions(account, Permissions.NOTIFICATION_VIEW) && {
            title: "Berichten",
            slug: "berichten",
            end: false,
        },
        checkAccountPermissions(account, Permissions.APPOINTMENT_VIEW) && {
            title: "Diagnostiekdag",
            slug: "afspraken",
            end: true,
        }
    ].filter(Boolean);

    // Set the user in localStorage for search history
    const [, setRecentlyViewedPatients] = useLocalStorage("recentlyViewedPatients", {type: "array", parentKey: account.id, maxLength: 8})
    useEffect(() => {
        setRecentlyViewedPatients(patientData.id)
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <StyledBackButton to={getHomePage(account)}>Patiëntenoverzicht</StyledBackButton>
            <FlexHeading>
                <div>
                    <PatientID>ID: {patientData?.medicoreID}</PatientID>
                    <PatientName>{patientData?.name}</PatientName>
                    <Text $noMargin $small>{patientData?.birthDate && `${patientData?.birthDate}, ${calculateAge(patientData?.birthDate)}`} jaar</Text>
                </div>

                <PatientActions>
                    <SyncButton patientUUID={patientUUID}/>
                    <CopyButton/>
                    {patientData?.medicoreUrl && (
                        <CustomLink to={patientData.medicoreUrl} type="external">
                            <Medicore/>
                        </CustomLink>
                    )}
                </PatientActions>
            </FlexHeading>


            { hasTimelineFeature ? (
                <TimelineWrapper>
                    <React.Suspense fallback={
                        <TimelineFallback>
                            <Spinner/>
                        </TimelineFallback>
                    }>
                        <Await resolve={timelinePromise} errorElement={<Text>De tijdlijn kan niet worden opgehaald. Probeer het opnieuw.</Text>}>
                            {(timelineData) => (
                                <Timeline data={timelineData}/>
                            )}
                        </Await>
                    </React.Suspense>
                </TimelineWrapper>
            ) : (
                <InfoListWrapper>
                    <InfoList>
                        <PrivateInfoListItem><strong>Naam:</strong> {patientData?.name && patientData.name}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>E-mailadres:</strong> {patientData?.email && patientData.email}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Voornamen:</strong> {patientData?.firstNames && patientData.firstNames}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Telefoonnummer mobiel:</strong> {patientData?.phoneNumberMobile && patientData.phoneNumberMobile}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Achternaam:</strong> {patientData?.lastName && patientData.lastName}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Telefoonnummer thuis:</strong> {patientData?.phoneNumberHome && patientData.phoneNumberHome}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Geboortedatum:</strong> {patientData?.birthDate && patientData.birthDate}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Telefoonnummer werk:</strong> {patientData?.phoneNumberWork && patientData.phoneNumberWork}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Gender:</strong> {patientData?.gender && patientData.gender}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Locatie:</strong> {patientData?.location?.[0] && patientData.location[0]}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Adres:</strong> {patientData?.address && patientData.address}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Verzekering:</strong> {patientData?.healthInsurance && patientData.healthInsurance}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>BSN:</strong> {patientData?.bsn && patientData.bsn}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Medicore ID:</strong> {patientData?.medicoreID && patientData.medicoreID}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Status:</strong> {patientData?.statuses?.[0]?.title && patientData.statuses[0].title}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>App gebruiker:</strong> {patientData?.account ? <>Ja<TrashIcon onClick={handleOpenDelete}/></> : <>Nee<EditIcon onClick={handleOpenAdd}/></>}</PrivateInfoListItem>
                        <PrivateInfoListItem><strong>Laatste app sessie:</strong> {patientData?.lastAppSession && patientData.lastAppSession}</PrivateInfoListItem>
                    </InfoList>

                    <DeleteAppAccount isOpen={isOpenDelete} handleClose={handleCloseDelete} />
                    <AddAppAccount isOpen={isOpenAdd} handleClose={handleCloseAdd} />
                </InfoListWrapper>
            )}

            <Tabs>
                {tabs.map(item => (
                    <NavLink key={item.slug} to={item.slug} end={item.end}>
                        {({isActive}) => (
                            <Tab $isActive={isActive}>
                                {item.title}
                            </Tab>
                        )}
                    </NavLink>
                ))}
            </Tabs>

            <Outlet/>
        </>
    );
}

export default Patient;